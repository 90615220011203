import { Link, navigate } from "gatsby";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { Grid } from "@material-ui/core";
import NewsletterRow from "../newsletter/NewsletterRow";
import PageTitle from "../core/PageTitle";
import Layout from "../core/Layout";
import ArticleCardV2 from "../../tailwindComponents/ArticleCardV2";
require("prismjs/themes/prism-tomorrow.css");

interface ArticlesProps {
  pageContext: {
    articles: any;
  };
}

export default function Articles(props: ArticlesProps) {
  const classes = useStyles();
  return (
    <>
      <Layout
        maxWidth="lg"
        pageTitle="Jetpack Compose Articles"
        pageDescription="Discover expert tutorials, articles and insights about Jetpack Compose. Stay updated with the latest in Android UI development at JetpackCompose.app "
        pageSlug="/articles"
        pageImageUrl="/articles_poster.png"
        seoImageUrl="/articles_poster.png"
      >
        <PageTitle header="Articles" subheader="" />
        <Grid
          container
          lg={12}
          xs={12}
          className={classes.articleGridContainer}
        >
          {props.pageContext.articles.map((article) => {
            return (
              <Grid item lg={4} xs={12} md={6}>
                <Grid
                  container
                  justify="center"
                  className={classes.cardContainer}
                >
                  <Link
                    to={article.frontmatter.slug}
                    partiallyActive={true}
                    onClick={() => {
                      // gtag isn't available in localhost so using this workaround
                      if (
                        window &&
                        !window.location.href.includes("localhost")
                      ) {
                        window.gtag(`event`, `click`, {
                          event_category: `engagement`,
                          event_label: article.frontmatter.slug,
                        });
                      }
                    }}
                  >
                    <ArticleCardV2
                      title={article.frontmatter.title}
                      description={article.frontmatter.description}
                      dynamicImage={article.frontmatter.heroImageUrl}
                      date={article.frontmatter.date}
                      tags={article.frontmatter.tags}
                    />
                  </Link>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
        <div className={classes.newsletter}>
          <NewsletterRow />{" "}
        </div>
      </Layout>
    </>
  );
}

const useStyles = makeStyles({
  cardContainer: {
    padding: 16,
  },
  articleGridContainer: {
    marginBottom: "96px",
  },
  newsletter: {
    marginBottom: 64,
    margin: "auto",
    maxWidth: 960,
    textAlign: "center",
  },
});
